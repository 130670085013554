import React from 'react'
import {
  CFImage,
  CFLink,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { locationMap, mobileMap } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFLink href="https://goo.gl/maps/XzbYRMNNzk5z7mPG6">
          <CFImage
            src={mobileMap}
            w="100%"
            alt="Chimac Korean Fried Chicken & Pub Map"
          />
        </CFLink>
      </MobileScreen>
      <DefaultScreen>
        <CFLink href="https://goo.gl/maps/XzbYRMNNzk5z7mPG6">
          <CFImage
            src={locationMap}
            w="100%"
            alt="Chimac Korean Fried Chicken & Pub Map"
          />
        </CFLink>
      </DefaultScreen>
    </CFView>
  )
}
