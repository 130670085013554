import React from 'react'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { mobileAbout, about, aboutText } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          column
          center
          // h="500px"
          // image={`url(${mobileAbout}) center / cover no-repeat`}
          // style={{ backgroundAttachment: 'fixed' }}
        >
          <CFImage src={mobileAbout} w="100%" alt="About" />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          row
          justifyEnd
          pv="10%"
          pr="40px"
          maxWidth="1400px"
          image={`url(${about}) left / cover no-repeat`}
          style={{ backgroundAttachment: 'fixed' }}
        >
          <CFImage src={aboutText} w="50%" alt="About" />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
